<template>
    <div class="content-wrapper">
        <PageHeader :screenName="form.titulo" :linkItems="linkItems"/>

        <ErrorModal
            :modalShow="modalError.showModal"
            :typeModal="modalError.typeModal"
            :caseModal="modalError.caseModal"
            @confirmed="redirecionarLista"
        />

        <main class="card p-2">
            <section class="form">
                <b-form>
                    <b-row>
                        <b-col lg="9" md="9" sm="12">
                            <b-form-group label="Título do aviso"
                            label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ form.titulo }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6" md="6" sm="12">
                            <b-form-group label="Conteúdo do aviso"
                            label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ form.conteudo }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>  
                        </b-col>
                        
                    </b-row>
                    <b-row>
                        <b-col lg="6" md="6" sm="12">
                            <b-form-group label="Perfis" 
                            label-class="font-weight-bold"
                            v-if="perfisSelecionados != ''">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div class="d-flex flex-wrap">
                                        <div 
                                        v-for="perfilSelecionado in perfisSelecionados" 
                                        :key="perfilSelecionado.id_perfil"
                                        class="rounded perfis"
                                        >
                                            {{perfilSelecionado.descricao}}
                                        </div>
                                    </div>
                                </vue-perfect-scrollbar>       
                            </b-form-group>
                            <b-form-group label="Perfis encaminhados" 
                            label-class="font-weight-bold"
                            v-else>
                                <span>
                                    -
                                </span>
                            </b-form-group>
                            </b-col>
                            <b-col lg="6" md="6" sm="12">
                            <b-form-group v-if="form.ajudaRelacionada.id_ajuda && form.ajudaRelacionada.topico" label="Ajuda relacionada">
                                <b-link  @click.prevent="redirectToAjuda" class="text-custom-blue d-flex align-items-center justify-center-center font-weight-bold"> 
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        class="text-custom-blue"
                                        style="margin-right: 5px"
                                        size="18"
                                    />
                                    {{ form.ajudaRelacionada.topico }}
                                </b-link> 
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </section>
            <section v-if="editable" class="buttons mt-3">
                <b-button id="edit-aviso" 
                    @click.prevent="redirectToEdit()"
                    variant="custom-blue"
                >
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">Editar</span>
                </b-button>
            </section>
        </main>
    </div>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BButton, BFormTextarea, BLink } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import moment from 'moment'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import ErrorModal from '@/views/components/custom/modals/ErroIdInexistenteModal.vue'
import { getIdAviso, getPerfisSelecionadosAviso } from '@core/utils/store/getStore';
import { setIdAviso, setPerfisSelecionadosAviso } from "@core/utils/store/setStore";

export default {
    title: 'Detalhes aviso',
    components: {
        BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BFormTextarea,
        BButton, PageHeader, VuePerfectScrollbar, ErrorModal, BLink
    },

    data() {
        return{
            settings: {
                maxScrollbarLength: 70,
                wheelSpeed: 0.2,
                wheelPropagation: false
            },
            linkItems: [
                {
                    name: 'Avisos',
                    routeName: 'aviso-list'
                },
                {
                    name: 'Aviso',
                    active: true
                }
            ],
            today: moment().format('YYYY-MM-DD'),
            editable: true,
            modalError: {
                showModal: false,
                typeModal: 'error',
                caseModal: 'idInexistente'
            },
            form: {
                titulo: '',
                conteudo: '',
                vigencia_inicial: '',
                vigencia_final: '',
                ativo: '',
                ajudaRelacionada: {
                    id_ajuda: '',
                    topico: ''
                },
            },
            perfisSelecionados: []
        }
    },
    mounted() {
        if((getIdAviso() === null || typeof getIdAviso() == 'undefined') && typeof this.$route.params.idNotificacao == "undefined") {
            return this.openModalError();
        }else if(typeof this.$route.params.idNotificacao != "undefined" && this.$route.params.idNotificacao !== getIdAviso()) {
            setIdAviso(this.$route.params.idNotificacao);
        }

        if(typeof this.$route.params.perfisSelecionados != "undefined" && this.$route.params.perfisSelecionados !== getPerfisSelecionadosAviso()) {
            setPerfisSelecionadosAviso(this.$route.params.perfisSelecionados);
        }

        this.$http.get(this.$api.notificacaoParameter(getIdAviso())).then(({ data }) => {
            this.preenchimentoFormulario(data);
        });

    },

    methods: {
        preenchimentoFormulario(data){
            this.idNotificacaoShow  = getIdAviso();
            this.form.titulo = data.titulo;
            this.form.conteudo = data.conteudo;
            this.form.vigencia_inicial = data.vigencia_inicial;
            this.form.vigencia_final = data.vigencia_final;
            this.form.ativo = data.ativo;
            this.form.ajudaRelacionada = (data.id_ajuda != null) ? data.id_ajuda : '';
            this.perfisSelecionados = this.getDadosPerfisSelecionadosAviso();

            if(moment(this.today).isAfter(this.form.vigencia_final) || !this.form.ativo){
                this.editable = false;
            }
        },
        redirecionarLista() {
            this.$router.push({ name: 'aviso-list' });
        },

        openModalError() {
            this.modalError.showModal = true;
        },
        redirectToEdit() {
            this.$router.push(
                { 
                    name: 'aviso-edit', 
                    params: {
                        idNotificacao: this.$route.params.idNotificacao,
                        titulo: this.$route.params.titulo,
                        conteudo: this.$route.params.conteudo,
                        vigencia_inicial: this.$route.params.vigencia_inicial,
                        vigencia_final: this.$route.params.vigencia_final,
                        ativo: this.$route.params.ativo,
                        ajudaRelacionada: this.$route.params.ajudaRelacionada,
                        perfisSelecionados: this.$route.params.perfisSelecionados
                    }
                }
            );
        },
        redirectToAjuda() {
            this.$router.push(
                { 
                    name: 'home', 
                    params: {
                        id_ajuda: this.form.ajudaRelacionada.id_ajuda
                    }
                }
            );
        },
        formatDate(value){
         if (value) {
           return moment(String(value)).format('DD/MM/YYYY');
          }
        },
        getDadosPerfisSelecionadosAviso() {
            if(getPerfisSelecionadosAviso() != ''){
                let idsPerfisSelecionados = this.$helpers.returnPgArrayToArray(getPerfisSelecionadosAviso(), ',');
                let dadosPerfis = [];
                idsPerfisSelecionados.forEach((perfil) => {
                    this.$http.get(this.$api.getPerfil(perfil)).then(({data}) => {
                        dadosPerfis.push(data);
                    })
                })
                return dadosPerfis;
            }
        },
    }
}
</script>

<style scoped>
.perfis{
    background-color: #2772C0;
    color: white;
    padding: 5px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-weight: 400;
}

.altura_max_input_scrollbar{
    max-height:32px;
    word-break: break-all;
    padding-right: 15px;
}
.altura_max_textarea_scrollbar{
    max-height:113px;
    word-break: break-all;
    padding-right: 15px;
}
</style>